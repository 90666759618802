import { ProjectType } from '../../types/projectType';
import { ProjectsReducerType, StoreAction } from '../redux.interface';

export const setAllProjects = (projects: ProjectType[]) => ({
  type: 'projects/SET_ALL_PROJECTS',
  payload: projects,
});

export const resetAllProjects = () => ({
  type: 'projects/RESET_ALL_PROJECTS',
});

export const setCurrentProject = (project: ProjectType) => ({
  type: 'projects/SET_CURRENT_PROJECT',
  payload: project,
});

export const resetCurrentProject = () => ({
  type: 'projects/RESET_CURRENT_PROJECT',
});

const initialState: ProjectsReducerType = {
  allProjects: null,
  currentProject: null,
};

export default function specificRulesReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case 'projects/SET_ALL_PROJECTS':
      const allProjects = action.payload;
      return {
        ...state,
        allProjects,
      };
    case 'projects/RESET_ALL_PROJECTS':
      return {
        ...state,
        allProjects: initialState.allProjects,
      };
    case 'projects/SET_CURRENT_PROJECT':
      const currentProject = action.payload;
      return {
        ...state,
        currentProject,
      };
    case 'projects/RESET_CURRENT_PROJECT':
      return {
        ...state,
        currentProject: initialState.currentProject,
      };
    default:
      return state;
  }
}

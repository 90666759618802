import { KeyboardReducerType, StoreAction } from '../redux.interface';
import { getState } from '../Store';

export const openKeyboard = (state: KeyboardReducerType) => ({
  type: 'keyboard/OPEN_KEYBOARD',
  payload: state,
});

export const closeKeyboard = () => ({
  type: 'keyboard/CLOSE_KEYBOARD',
});
export const availableKeyboard = (available: boolean) => ({
  type: 'keyboard/AVAILABLE_KEYBOARD',
  payload: { available: available },
});

const initialState: KeyboardReducerType = {
  show: false,
  input: '',
  available: false,
  setInput: (value) => {},
};

export default function specificRulesReducer(state = initialState, action: StoreAction) {
  const keyboardState = action.payload;
  switch (action.type) {
    case 'keyboard/OPEN_KEYBOARD':
      return {
        ...keyboardState,
        available: state.available,
      };
    case 'keyboard/CLOSE_KEYBOARD':
      return {
        ...initialState,
        available: state.available,
      };
    case 'keyboard/AVAILABLE_KEYBOARD':
      return {
        ...initialState,
        ...keyboardState,
      };
    default:
      return state;
  }
}

import { KeyboardReducerType } from '../redux.interface';
import { GenericThunkAction } from '../Store';
import { openKeyboard, closeKeyboard, availableKeyboard } from './keyboardReducer';

export const openKeyboardAction =
  (state: KeyboardReducerType): GenericThunkAction<typeof openKeyboard> =>
  (dispatch: any, getState: any) => {
    dispatch(openKeyboard(state));
  };

export const closeKeyboardAction = (): GenericThunkAction<typeof closeKeyboard> => (dispatch: any) => {
  dispatch(closeKeyboard());
};

export const setKeyboardAvailableAction =
  (available: boolean): GenericThunkAction<typeof availableKeyboard> =>
  (dispatch: any) => {
    dispatch(availableKeyboard(available));
  };

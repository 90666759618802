import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import styles from './customKeyboard.module.scss';
import './CustomKeyboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardReducerType } from '../../redux/redux.interface';
import { RootState } from '../../redux/Store';
import { keyboardSelector } from '../../redux/keyboard/keyboardSelectors';
import * as ViewUtils from '../../utils/viewUtils';
import { Button, Col, Container, Input, Modal, ModalBody, Row } from 'reactstrap';
import { closeKeyboardAction, setKeyboardAvailableAction } from '../../redux/keyboard/keyboardActions';

export const CustomKeyboard = () => {
  const { input, setInput, show, isPassword, defaultLayout, available } = useSelector<RootState, KeyboardReducerType>(
    (state) => keyboardSelector(state),
  );
  const [innerInput, setInnerInput] = useState('');
  const [layoutName, setLayoutName] = useState('default');
  const keyboardRef = useRef<any>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const showOnScreenKeyboard: boolean = available === true;

  const customStyles = {
    '--bs-modal-width': '90vw',
    maxWidth: '90vw',
    minWidth: '90vw',
  } as React.CSSProperties;
  useEffect(() => {
    keyboardRef.current?.setInput(input);
    setInput(input);
    setInnerInput(input);
  }, [input]);
  window.addEventListener('load', () => {
    const showOnScreenKeyboard = window.location.search.includes('showOnScreenKeyboard');
    dispatch(setKeyboardAvailableAction(showOnScreenKeyboard));
  });

  useEffect(() => {
    if (show) {
      setInput(input);
      setInnerInput(input);
      if (defaultLayout) setLayoutName(defaultLayout);
    }
  }, [show, defaultLayout, input]);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  });
  const onKeyPress = (button: string) => {
    if (button.includes('{') && button.includes('}')) {
      let newLayout;
      switch (button) {
        case '{shift}':
        case '{default}':
          newLayout = layoutName === 'default' ? 'shift' : 'default';
          break;

        case '{alt}':
          newLayout = layoutName === 'alt' ? 'default' : 'alt';
          break;

        default:
          if (defaultLayout) newLayout = defaultLayout;
          break;
      }
      if (newLayout) setLayoutName(newLayout);
    }
  };

  const layout = {
    // prettier-ignore
    default: [
        'q w e r t y u i o p', 
        'a s d f g h j k l', 
        '{shift} z x c v b n m {bksp}', 
        '{alt} , {space} .'
    ],
    // prettier-ignore
    shift: [
      'Q W E R T Y U I O P', 
      'A S D F G H J K L', 
      '{shift} Z X C V B N M', 
      '{alt} , {space} .'
    ],
    // prettier-ignore
    alt: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}', 
      `@ # $ & * ( ) [ ] ' "`, 
      '% ^ _ - + = / ; : ! ?', 
      '{default} {space} {default}'
    ],
    email: ['q w e r t y u i o p', 'a s d f g h j k l', '{shift} z x c v b n m {bksp}', '{alt} @ {space} .'],
  };
  const display = {
    '{alt}': '.?123',
    '{bksp}': '⌫',
    '{shift}': '⇧',
    '{default}': 'ABC',
    '{space}': ' ',
  };
  const onChangeInput = (_input: string) => {
    setInput(_input);
    setInnerInput(_input);
    keyboardRef.current?.setInput(_input);
  };

  return !showOnScreenKeyboard ? (
    <></>
  ) : (
    <Container>
      <Modal centered className={styles.keyboard_modal} isOpen={show} style={customStyles}>
        <ModalBody className={styles.modal_dialog}>
          <Row className='justify-content-center w-100'>
            <Col xs={8}>
              {isPassword === true && (
                <Input
                  type='password'
                  value={innerInput}
                  id={'id_keyboard_InputPasswordWithEye'}
                  onChange={(e) => onChangeInput(e.target.value)}
                  innerRef={inputRef}
                  style={{ width: '90%' }}
                />
              )}
              {!isPassword && (
                <Input
                  value={innerInput}
                  onChange={(e) => onChangeInput(e.target.value)}
                  innerRef={inputRef}
                  style={{ width: '90%' }}
                  id={'id_keyboard_InputText'}
                />
              )}
            </Col>
            <Col xs={2}>
              <Button
                variant='primary'
                onClick={(e) => {
                  dispatch(closeKeyboardAction());
                }}
              >
                Ok
              </Button>
            </Col>
          </Row>

          <ModalBody className={styles.myKeyboardContainer}>
            <Keyboard
              layoutName={layoutName}
              display={display}
              layout={layout}
              onChange={onChangeInput}
              keyboardRef={(r) => (keyboardRef.current = r)}
              onKeyPress={(e) => onKeyPress(e)}
              preventMouseDownDefault={true}
            />
          </ModalBody>
        </ModalBody>
      </Modal>
    </Container>
  );
};

import { CompanyType } from '../../types/companyType';
import { CompaniesReducerType, StoreAction } from '../redux.interface';

export const setSubCompanies = (companies: CompanyType[]) => ({
  type: 'companies/SET_SUB_COMPANIES',
  payload: companies,
});

export const resetSubCompanies = () => ({
  type: 'companies/RESET_SUB_COMPANIES',
});

export const setUserCompany = (userCompany: CompanyType) => ({
  type: 'companies/SET_USER_COMPANY',
  payload: userCompany,
});

export const resetUserCompany = () => ({
  type: 'companies/RESET_USER_COMPANY',
});

const initialState: CompaniesReducerType = {
  subCompanies: null,
  userCompany: null,
};

export default function specificRulesReducer(state = initialState, action: StoreAction) {
  switch (action.type) {
    case 'companies/SET_SUB_COMPANIES':
      const subCompanies = action.payload;
      return {
        ...state,
        subCompanies,
      };
    case 'companies/RESET_SUB_COMPANIES':
      return {
        ...state,
        subCompanies: initialState.subCompanies,
      };
    case 'companies/SET_USER_COMPANY':
      const userCompany = action.payload;
      return {
        ...state,
        userCompany,
      };
    case 'companies/RESET_USER_COMPANY':
      return {
        ...state,
        userCompany: initialState.userCompany,
      };
    default:
      return state;
  }
}

import { createStore, applyMiddleware, AnyAction } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
export const dispatch = store.dispatch;
export const getState: () => RootState = store.getState;
(window as any).getState = getState;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type GenericThunkAction<ActionCreator extends (...args: Array<any & unknown & any[]>) => AnyAction> =
  ThunkAction<void, RootState, undefined, ReturnType<ActionCreator>>;

import { combineReducers, Reducer } from 'redux';
import settings from './settings/Reducer';
import devices from './devices/devicesReducer';
import projects from './projects/projectReducer';
import companies from './companies/companyReducer';
import keyboard from './keyboard/keyboardReducer';
import {
  CompaniesReducerType,
  DevicesReducerType,
  KeyboardReducerType,
  ProjectsReducerType,
  TicketsReducerType,
} from './redux.interface';

const rootReducer = combineReducers({
  settings,
  devices: devices as Reducer<DevicesReducerType, any>,
  projects: projects as Reducer<ProjectsReducerType, any>,
  companies: companies as Reducer<CompaniesReducerType, any>,
  keyboard: keyboard as Reducer<KeyboardReducerType, any>,
});

export default rootReducer;

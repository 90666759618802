import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './assets/scss/templateStyles/style.scss';
import './assets/scss/custom.scss';
import './assets/scss/customStyles/react-table.css';
import './assets/scss/customStyles/style.scss';
import Spinner from './views/spinner/Spinner';
import { Provider } from 'react-redux';
import store from './redux/Store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';
import { CustomKeyboard } from './components/customKeyboard/CustomKeyboard';

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./app')), 0);
    }),
);
const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <CustomKeyboard />
        <App />
      </QueryClientProvider>
    </Provider>
  </Suspense>,
);

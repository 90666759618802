// SETTINGS CONSTANTS
export const LOGO_BG = 'LOGO_BG';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const THEME = 'THEME';
export const DIRECTION = 'DIRECTION';
export const SIDEBAR_POSITION = 'SIDEBAR_POSITION';
export const HEADER_POSITION = 'HEADER_POSITION';
export const LAYOUT = 'LAYOUT';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';

// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// TODO CONSTANTS
export const FETCH_TODO_REQUEST = 'FETCH_TODO_REQUEST';
export const FETCH_TODO_SUCCESS = 'FETCH_TODO_SUCCESS';
export const FETCH_TODO_FAILURE = 'FETCH_TODO_FAILURE';
export const TOGGLE_STARRED_TODO = 'TOGGLE_STARRED_TODO';
export const SET_VISIBILITY_FILTER_TODO = 'SET_VISIBILITY_FILTER_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const TODO_DETAILS = 'TODO_DETAILS';
export const FILTER_TODO = 'FILTER_TODO';
export const COMPLETE_TODO = 'COMPLETE_TODO';
export const UPDATE_TODO = 'UPDATE_TODO';
export const ADD_TODO = 'ADD_TODO';

// CONTACT CONSTANTS
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CONTACT_DETAILS = 'CONTACT_DETAILS';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const FILTER_CONTACT = 'FILTER_CONTACT';
export const TOGGLE_STARRED_CONTACT = 'TOGGLE_STARRED_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';

// NOTES CONSTANTS

export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

export const PROJECT_ID = 'PROJECT_ID';
export const COMPANY_ID = 'COMPANY_ID';
export const DESKTOP_LAYOUT = 'DESKTOP_LAYOUT';
